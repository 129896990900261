.items_toggle:hover {
  cursor: pointer;
}
.items_toggle {
  margin-bottom: 10px;
  font-size: 10px;
  display: block;
  font-weight: bold;
  text-decoration: underline;
  color: rgb(1, 56, 255);
}
