#status_details_modal {
  width: 100%;
  max-width: 60vw;
}

.history_toggle:hover {
  cursor: pointer;
}
.history_toggle {
  margin-bottom: 10px;
  font-size: 10px;
  display: block;
  font-weight: bold;
  text-decoration: underline;
  color: rgb(1, 56, 255);
}

.undelivered_status_modal {
  width: 100%;
  max-width: 80vw;
}

.status_count:hover {
  cursor: pointer;
}
